import * as React from 'react';
import HeaderWithParagraphList from '../../components/headerWithParagraphList/headerWithParagraphList';
import * as Styled from './troublewithcylinderTemplateStyle';
import { TroubleData, Emptydata } from '../../utils/constant';
import BootstrapNavbar from '../../components/navgationBar/navigationBar';
import BackButton from '../../components/helpBackButton/helpBackButton';

const TroubleWithCylindertemplate = () => {
  return (
    <>
      <BootstrapNavbar />
      <Styled.Container>
        <Styled.Subcontainer>
          <Styled.Header1>TROUBLE WITH YOUR CYLINDER?</Styled.Header1>
          <Styled.Content>
            <HeaderWithParagraphList
              subHeader="The hand wheel is tight and will not open"
              orderedList={TroubleData.orderedList1}
            />
            <HeaderWithParagraphList
              subHeader="I think my cylinder is leaking"
              orderedList={TroubleData.orderedList2}
            />
            <Styled.Header5>
              My cylinder is connected but still won’t work
            </Styled.Header5>
            <HeaderWithParagraphList
              subHeader=" My cylinder has no/low flow rate or a very low flame"
              paragraph={TroubleData.paragraph}
            />
            <HeaderWithParagraphList paragraph={TroubleData.paragraph1} />
            <HeaderWithParagraphList orderedList={TroubleData.orderedList3} />
            <HeaderWithParagraphList
              subHeader="The purchased or exchanged cylinder is empty"
              paragraph={Emptydata.paragraph}
            />
          </Styled.Content>
        </Styled.Subcontainer>
        <BackButton />
      </Styled.Container>
    </>
  );
};

export default TroubleWithCylindertemplate;
