import React from 'react';
import { Helmet } from 'react-helmet';
import TroubleWithCylindertemplate from '../templates/troubleWithCylinderTemplate/troublewithcylinderTemplate';
import Footer from '../components/footer/footer';
import GlobalStyles from '../styles/globalStyles';
export default function TroubleWithCylinder() {
  return (
    <>
      <GlobalStyles />
      <Helmet>
        <title>Trouble with your Propane Cylinder? | Pro2Go</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0"
        ></meta>
        <meta name="format-detection" content="telephone=no"></meta>
        <meta
          name="description"
          content="Instructions for fixing potential bbq propane cylinder issues."
        ></meta>
        <meta
          name="keywords"
          content="bbq cylinders,propane cylinders,cylinder exchange service,london ontario,canad."
        ></meta>
      </Helmet>
      <TroubleWithCylindertemplate />
      <Footer />
    </>
  );
}
