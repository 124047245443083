import styled from 'styled-components';

export const Container = styled.div`
  width: 970px;
  margin: 0 auto;
  margin-top: 100px;
  padding: 0 15px;
  @media (max-width: 991px) {
    width: 100%;
    margin-top: 20px;
    padding: 0 30px;
  }
`;
export const Subcontainer = styled.div`
  display: block;
  font-size: 14px;
  direction: ltr;
  font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
  line-height: 1;
  color: #222;
  position: relative;
  -webkit-font-smoothing: antialiased;
`;

export const Header1 = styled.h1`
  font-family: 'Dosis', sans-serif;
  font-weight: 700;
  color: #4e4e4e;
  font-size: 1.75em;
  line-height: 1em;
  text-transform: uppercase;
  margin: 20px 0;
  font-style: normal;
  text-rendering: optimizeLegibility;
  margin-bottom: 14px;
  padding: 0;
  direction: ltr;
`;
export const Content = styled.div`
  text-align: justify !important;
`;
export const Header5 = styled.h5`
  font-family: 'Titillium Web', sans-serif;
  font-weight: 700;
  color: #263b91;
  font-size: 1.25em;
  line-height: 1em;
  font-style: normal;
  margin-bottom: 1px !important;
  text-rendering: optimizeLegibility;
  direction: ltr;
  padding-left: 10px;
  @media (max-width: 991px) {
    padding-left: 0px;
  }
`;
